import { Button } from "@mui/material";
import { IconOnlyButton } from "modules/picasso-ui/interact/IconOnlyButton";
import { PopoverMenu } from "modules/picasso-ui/menu/PopoverMenu"
import { forwardRef } from "react"

export const ListAssistButton = forwardRef((props, ref) => {

    let { iconOnly, variant, color, size, ...otherProps } = props

    color = color || 'greyDark'
    variant = variant || 'outlined'
    size = size || 'small'

    if (iconOnly) {
        return <IconOnlyButton
            {...otherProps}
            color={color}
            variant={variant}
            size={size}
            ref={ref}
        />
    }

    return <Button 
            {...otherProps}
            color={color}
            variant={variant}
            size={size}
            ref={ref}
        />

})

const menuProps = {
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: "top", horizontal: "left" },
}

export const ListAssistMenuButton = forwardRef(({actions, actionGroups, children, icon, buttonProps, PopoverMenuProps, ...props}, ref) => {
    
    const renderToggle = (toggleProps) => 
        <ListAssistButton
            {...toggleProps}
            {...props}
            startIcon={buttonProps?.iconOnly !== true ? icon : null}
            {...buttonProps}
            aria-haspopup="true"
            ref={ref}
        >
            {buttonProps.iconOnly ? icon : children}
        </ListAssistButton>

    const multiGroups = actionGroups?.length > 1

    const PaperProps = {
        sx:theme=>({
            [theme.breakpoints.up('md')]: {
                //allow to place groups next to each other on large screens
                width: multiGroups ? '600px':'250px',
            },
            [theme.breakpoints.down('md')]: {
                width: '250px',
            },
        }),
    }

    return (
        <PopoverMenu
            menuProps={menuProps}
            PaperProps={PaperProps}
            actions={actions}
            actionGroups={actionGroups}
            renderToggle={renderToggle}
            closeOnClick={false}
            {...PopoverMenuProps}
        />
    )

})