import { ClickAwayListener } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { useRef } from "react";
import { ListAssistButton } from "../present/list/ListAssistButton";
import { useDebounced } from "../form/useDebounced";
import SearchBar from "./filter/SearchBar";
import { joinClasses } from "utils/muiUtils";
import { VerticalAlignBox } from "../layout/VerticalAlignBox";

const useStyles = makeStyles(()=>({
    searchInput: {
        height: '38px',
        display: 'flex',
        alignItems: 'center',
    },
    searchIcon: {
/*         fontSize: '16px',
        color: '#dcdcdc', */
    },
    searchHidden: {
        visibility: 'hidden',
        width: '1px',
        height: '1px',
    },
    searchContained: {
        border: 'none',
        backgroundColor: '#eff2f5',
        boxShadow: 'none',
        height: '38px',
    },
    searchOutlined: {
        border: '1px solid #d0d4da',
        boxShadow: '0 1px 2px 0 rgb(17 24 38 / 6%)',
    },
    searchSmall: {
        height: '34px',
    },
    searchWide: {
        width: '500px',
        maxWidth: '100%',
    },
}))

export const SearchInput = ({value, initialValue, buttonProps, onChange, debounced, debounceTimeout, inputProps, variant, size, presets, wide, ...props}) => {
    
    const classes = useStyles()

    const [open,setOpen] = useState(false)

    const searchRef = useRef()

    const [initialValueEffective, setInitialValueEffective] = useState(initialValue)

    const openedByBtnClick = useRef()

    useEffect(()=>{
        if (!open) {
            // Order important. openedByBtnClick.current must be set to false always.
            openedByBtnClick.current = false
            return;
        }
        if (!searchRef.current) {
            return;
        }

        if (openedByBtnClick.current === true && searchRef.current.focus) {
            //console.log('focus')
            searchRef.current.focus()
        }

    },[searchRef.current, open])

    useEffect(()=>{
        if (value?.length > 0 || initialValue?.length > 0) {
            if (!open) {
                setOpen(()=>true)
            }
             // Update initial value only if user is not typing in it
            if (initialValue && document.activeElement !== searchRef.current) {
                setInitialValueEffective(initialValue)
            }
        } else if (open) {
            setOpen(()=>false)
            if (!initialValue && initialValueEffective) {
                setInitialValueEffective('')
            }
        }
    },[value, initialValue])

    const handleSearchBtnClick = () => {
        openedByBtnClick.current = true
        setOpen(true)
    }

    const handleClickAway = () => {
        if (!searchRef.current) {
            return;
        }

        const currentInput = searchRef.current.value
        if (!currentInput || currentInput.length === 0) {
            setOpen(false)
        }
    }

    const handlePresetClicked = (p) => {
        onChange(p.value)
    }

    const { handleDebounced } = useDebounced()

    const handleChangeDebounced = handleDebounced((updated) => {
        onChange(updated)
    }, debounceTimeout || 300)

    let searchClasses = []

    variant = variant || 'contained'

    if (variant === 'contained') {
        searchClasses.push(classes.searchContained)
    }
    else if (variant === 'outlined') {
        searchClasses.push(classes.searchOutlined)
    }

    if (size === 'small') {
        searchClasses.push(classes.searchSmall)
    }

    if (wide) {
        searchClasses.push(classes.searchWide)
    }

    const valueEffective = initialValueEffective && !value ? initialValueEffective : value

    return (
        <div className={classes.searchInput}>
            {open !== true ?
                <ListAssistButton 
                    onClick={handleSearchBtnClick} 
                    size="small"
                    iconOnly={true}
                    {...buttonProps}
                >
                    &nbsp;<SearchIcon className={classes.searchIcon}/>&nbsp;
                </ListAssistButton>
                :
                <ClickAwayListener onClickAway={handleClickAway}>
                    <VerticalAlignBox>
                        {presets?.map(p=><span key={p.name} css={theme=>({ cursor: 'pointer', fontSize: '10px', '&:hover': {color: theme.palette.primary.main} })} onClick={()=>handlePresetClicked(p)}>{p.name}</span>)}
                        <SearchBar
                            className={joinClasses(searchClasses)}
                            cancelOnEscape={true}
                            {...props}
                            value={valueEffective}
                            ref={searchRef}
                            onChange={onChange ? (debounced === true ? handleChangeDebounced : onChange) : null}
                            onCancelSearch={onChange}
                            inputProps={inputProps}
                        />
                    </VerticalAlignBox>
                </ClickAwayListener>
            }
        </div>
    )

}