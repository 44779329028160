import { CSSProperties, FunctionComponent, PropsWithChildren, ReactNode } from "react"

export interface BlurredContentProps {
    radius?: string
    text?: ReactNode
    disabled?: boolean
    textPosition?: 'center' | 'left'
    style?: CSSProperties
}

export const BlurredContent: FunctionComponent<PropsWithChildren<BlurredContentProps>> = ({children, radius, text, disabled, textPosition, style}) => {

    radius = radius || '5px'

    textPosition = textPosition || 'center'  

    const styleText: CSSProperties = textPosition === 'center' 
        ? {position: 'absolute', top: '40%', left: '50%', right: '50%', transform: 'translateX(-50%) translateY(-50%)'}
        : (textPosition === 'left'
            ? { position: 'absolute', top: '50%', transform: 'translateY(-50%)', maxWidth: '100%' }
            : { position: 'absolute' }
            )

    return (
        <div style={{position: 'relative'}}>
            <div style={disabled ? null:{
                filter: 'blur('+radius+')',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                userSelect: 'none',
                ...style
            }}>
                {children}
            </div>
            {!disabled && text && <div style={styleText}>{text}</div>}
        </div>
    )

}