import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTranslation } from "next-i18next";
import { ListAssistMenuButton } from "./ListAssistButton";

export const SortButton = ({options, sort, onChange, buttonProps, defaultOptions, showText, iconStyle}) => {

    const { t } = useTranslation('common')

    const isAsc = sort ? 
        (sort.startsWith('-') ? false : true) : null;

    const sortProperty = sort ? 
            (isAsc ? sort : sort.substring(1, sort.length))
            : null

    const defaultOptionsMap = {
        name: {
            key: 'name',
            title: t('common:element.name')
        },
        createdAtTs: {
            key: 'createdAtTs',
            title: t('common:element.createdAt')
        },
        createdAt: {
            key: 'createdAt',
            title: t('common:element.createdAt')
        }
    }

    const handleChange = (updated) => {
        onChange(updated);
        //setSort(updated);
    }

    const handleOptionClick = (o) => {
        if (o.key === sortProperty) {
            return;
        }
        if (isAsc === true) {
            handleChange(o.key);
        } else if (isAsc === false) {
            handleChange('-'+o.key);
        } else {
            handleChange(o.key);
        }
    }

    const allOptions = [...options]
    if (defaultOptions && defaultOptions.length > 0) {
        defaultOptions.forEach(o=>{
            const defO = defaultOptionsMap[o]
            if (defO) {
                allOptions.push(defO)
            }
        });
    }

    const actions = allOptions.map(o=>(
        {   
            title: o.title, 
            handleClick: ()=>handleOptionClick(o),
            isSelected: o.key === sortProperty,
        }
    ))


    const handleAscClick = () => {
        if (isAsc) {
            return;
        }
        handleChange(sortProperty);
    }

    const handleDescClick = () => {
        if (!isAsc) {
            return;
        }
        handleChange('-'+sort);
    }

    actions.push({
        type: 'divider'
    })
    actions.push({
        title: t('data.sortorder.asc'),
        isSelected: isAsc,
        handleClick: handleAscClick
    })
    actions.push({
        title: t('data.sortorder.desc'),
        isSelected: !isAsc,
        handleClick: handleDescClick
    })

    //const sortPropertyTitle = allOptions.find(o=>o.key === sortProperty)?.title;

    //const sortPropertyTitleAbbr = sortPropertyTitle.length < 12 ? sortPropertyTitle : sortPropertyTitle.substring(0,9)+'...';

    return (
        <ListAssistMenuButton
            icon={<ImportExportIcon style={iconStyle}/>}
            actions={actions}
            buttonProps={{
                iconOnly: !showText,
                ...buttonProps
            }}
            PopoverMenuProps={{
                paperMaxHeight: null
            }}
        >   
           {t('data.action.sort')}
        </ListAssistMenuButton>
    )

}