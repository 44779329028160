import { getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { notNullNotUndefined } from 'utils/objectUtils';

export const changeAnsweringUser = (backlogId, userId) => getAxiosDefault().post(`/backlogs/${backlogId}/changeAnsweringUser`, {userId:userId||null})

export const getRatingItems = (backlogId) => getAxiosDefault().get(`/backlogs/${backlogId}/ratings/1/items`)

export const getBacklogRatingMy = (backlogId, ignoreRatingsMy) => {
  let params = {}
  if (ignoreRatingsMy) {
    params = params || {}
    params.ignoreRatingsMy = ignoreRatingsMy
  }
  return getAxiosDefault().get(`/backlogs/${backlogId}/rating/my/`, { params }).then((response) => {
    return response.data;
  })
}

export const getBacklogRatingMySettings = (backlogId) =>
  getAxiosDefault().get(`/backlogs/${backlogId}/rating/my/settings`).then((response) => {
    return response.data;
  })

export const getRatingUserValues = (backlogId) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/ratings/1/userValues`).then(res => res.data)
}

export const ratingSettingsFactorAdd = ({ backlogId, data }) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(data)
  return getAxiosDefault().post(`/backlogs/${backlogId}/ratingSettings/factors`, data)
}

export const ratingSettingsFactorDelete = ({ backlogId, factorId }) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(factorId)
  return getAxiosDefault().delete(`/backlogs/${backlogId}/ratingSettings/factors/${factorId}`)
}

export const getAnsweringMyState = (backlogId, ignoreRatingsMy, sessionStepsFinished) => {
  let params = {}
  if (ignoreRatingsMy) {
    params = params || {}
    params.ignoreRatingsMy = ignoreRatingsMy
  }
  if (sessionStepsFinished?.length > 0) {
    params = params || {}
    params.sessionStepsFinished = sessionStepsFinished
  }
  return getAxiosDefault().get(`backlogs/${backlogId}/answering/my/state`, { params })
}

export const restartAnsweringMy = (backlogId) => getAxiosDefault().post(`/backlogs/${backlogId}/answering/my/restart`)

export const getDelimitStep = (backlogId) => getAxiosDefault().get(`backlogs/${backlogId}/answering/delimit/myUnanswered`)

export const getDelimitStepForFactor = (backlogId, factorId) => getAxiosDefault().get(`backlogs/${backlogId}/answering/delimit/my/factors/${factorId}`)

export const saveAnswerDelimit = (backlogId, data) => getAxiosDefault().post(`backlogs/${backlogId}/answer/delimit`, data)

export const getAnsweringUserSettingsOfUser = (backlogId, userId) => getAxiosDefault().get(`backlogs/${backlogId}/answering/userSettings/${userId}`)