import { Button } from "@mui/material"
import { forwardRef } from "react"

export const IconOnlyButton = forwardRef(({children, ...props}, ref) => {

    const stylesSmall = {
        width: '32px',
        minWidth: '32px',
        '& svg': {
            fontSize: '16px',
        }
    }

    const stylesMedium = {
        width: '42px',
        minWidth: '42px',
    }

    const styles = props.size === 'small' ? stylesSmall : stylesMedium

    return <Button 
        {...props} 
        sx={[styles, props.sx]}
        ref={ref}
    >
     &nbsp;{children}&nbsp;
    </Button>

})