import { useSignedIn } from "modules/react-auth"
import { hasGroupInEac, hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import { useErrorNotify } from "modules/yoio/errorsService"
import alerts from "modules/yoio/state/alerts"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import useSWR from "swr"
import { isHavingValue } from "utils/objectUtils"
import { useRouterUtils } from "utils/routerUtils"
import { useBacklogEacContext, useBacklogEacSwr, useBacklogIdContext } from "./BacklogContext"

export interface RoomRoleReturn {
    roomRole: 'moderator' | 'guest'
    action: 'standard' | 'rank'
}

export const useRoomRole = (backlogId: string, roomRoleOverride?: 'moderator' | 'guest'):RoomRoleReturn => {

    const { notify } = useErrorNotify()

    const router = useRouter()

    const { me, hasPermission } = useSignedIn()

    const { data: eac } = useBacklogEacSwr(backlogId)

    const routerUtils = useRouterUtils()

    const actionInitial = useRef<'standard' | 'rank'>(null)

    const resolveRoomRole = () => {
        if (actionInitial.current === 'rank') {
            return 'guest'
        }
        if (router.query.role === 'guest') {
            return 'guest'
        }
        if (router.query.terminalKey) {
            return 'guest'
        }

        if (eac?.groups?.list?.includes('backlogOwner')) {
            return 'moderator'
        }
        if (router.query.role === 'moderator' || roomRoleOverride === 'moderator') {
            if (hasPermission('appManage')) {
                return 'moderator'
            } else {
                const e = new Error('Unexpected error')
                alerts.error('Unexpected error')
                notify(e)
                return null;
            }
        }

        return 'guest'
    }

    const resolveAction = () => {
        if (router.query.action) {
            if (hasGroupInEac(eac, 'backlogOwner')) {
                actionInitial.current = router.query.action as ('standard' | 'rank' | null)
            } else {
                // if action=rank is opened by non-owner, ignore it and behave as if there was no parameter, and remove the parameter
                routerUtils.deleteQueryParam('action')
            }
        } else {
            if (router.query.terminalKey) {
                actionInitial.current = 'rank'
            }
        }

        if (actionInitial.current) {
            return actionInitial.current
        }

        return null
    }

    const { data: roomRole, mutate: mutateRoomRole } = useSWR<'moderator' | 'guest'>(router.isReady && me && eac ? `/rankings/${backlogId}/roomRole/${eac.userId}` : null, ()=>resolveRoomRole(), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    const { data: action, mutate: mutateAction } = useSWR<'standard' | 'rank'>(router.isReady && me && eac ? `/rankings/${backlogId}/action/${eac.userId}` : null, ()=>resolveAction(), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    useEffect(()=>{
        if (!me) {
            return;
        }
        if (!eac) {
            return;
        }

        mutateAction()
        mutateRoomRole()
    },[me, eac])


    return {
        roomRole,
        action,
    }

}