import { Box, InputBase, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { isHavingValue, isNullOrUndefined, orElse } from '../../utils/objectUtils';

const styles = {
  lazyInteractive: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}

const InputInlineSingle = forwardRef((props, ref) => {
  
  let { value, onChange, minLength, maxLength, placeholder, variant, 
    disabled, highlightOnHover, likeH2, alignCenter, noDebounce, fullWidth, maxWidth, adjustWidthToText, inputRef, 
    ellipsisOnOverflowEnabled,
    tooltipOnOverflowEnabled, className, tooltipPlacement, bold, lazyInteractive } = props

      const [valueInternal, setValueInternal] = useState(value)
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState(null)


  const [interactive, setInteractive] = useState()

  const handleMouseOverLazyInteractive = () => {
    // Lazy interactive because otherwise the table freezes (nested Mui TextField is unperformant in table)
    if (!interactive) {
        setInteractive(true)
    }
  }

  const debounceInterval = noDebounce === true ? 0 : 500;

  maxLength = maxLength || 100;

  const [focused, setFocused] = useState()

  if (adjustWidthToText !== true) {
    fullWidth = orElse(fullWidth, true)
  }

  const debouncedValueInternal = useDebounce(valueInternal, debounceInterval);

  const [inputBaseStyle, setInputBaseStyle] = useState({
    style: {},
    overflows: false,
  })

  ref = ref || useRef()
  const textWidthMeasurerRef = useRef()

  const adjustWidth = () => {
    if (!textWidthMeasurerRef.current) {
      return;
    }
    if (!ref.current) {
      return;
    }
    if (adjustWidthToText === true && textWidthMeasurerRef.current.offsetWidth) {
      const rightPufferSpace = likeH2 === true ? 20 : 15 //otherwise text in box jumps on large fonts

      const inputWidthEffective = Math.min((textWidthMeasurerRef.current.offsetWidth)+rightPufferSpace, (maxWidth||550));

      const inputBaseStyle = {
        style: {},
      }
      inputBaseStyle.overflows = textWidthMeasurerRef.current.offsetWidth > ref?.current?.offsetWidth

      //console.debug('inputBaseStyle', inputBaseStyle, textWidthMeasurerRef.current.offsetWidth, ref?.current?.offsetWidth)
      //console.debug(inputBaseStyle.overflows, textWidthMeasurerRef.current.offsetWidth, ref?.current?.offsetWidth)
      
      inputBaseStyle.style.width = inputWidthEffective + "px";
      inputBaseStyle.style.maxWidth = '100%';

      setInputBaseStyle(inputBaseStyle)
    }
  }

  const onRefChange = useCallback(node => {
    if (node) { 
      textWidthMeasurerRef.current = node
      adjustWidth()
    }
  }, [])

  useEffect(() => {
    adjustWidth()
  }, [textWidthMeasurerRef.current?.offsetWidth, ref.current]);

  useEffect(() => {
    if (touched) {
      if (isNullOrUndefined(error)) {
        if (onChange) {
          onChange(valueInternal);
        }
      }
    }
  }, [debouncedValueInternal]);

  const isValueChanged = (newValue) => {
    return newValue != valueInternal;
  };

  const classes = useStyles();

  const buildClassNames = () => {
    let classNames = [];
    if (variant) {
      classNames.push(classes[variant]);
    } 
    if (highlightOnHover === true) {
      classNames.push(classes.highlightOnHover);
    }
    if (likeH2 === true) {
      classNames.push(classes.likeH2);
    }
    if (bold === true) {
      classNames.push(classes.bold);
    }
    if (alignCenter === true) {
      classNames.push(classes.alignCenter);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.join(' ');
  }

  const buildClassNamesMeasurer = () => {
    let classNames = [classes.measurer];
    if (variant) {
      classNames.push(classes[variant]);
    }
    if (likeH2 === true) {
      classNames.push(classes.likeH2Hidden);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.join(' ');
  }

  const handleChange = (e) => {
    setTouched(true);
    const newValue = e.target.value;
    if (isValueChanged(newValue) === true) {
      if (newValue.length < minLength) {
        setError('name required');
      } else if (isHavingValue(error)) {
        setError(null);
      }

      setValueInternal(newValue);
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const renderInputBase = () => {
    return (
        <InputBase
        ref={ref}
        disabled={disabled}
        name="project"
        variant="outlined"
        placeholder={placeholder}
        autoComplete="off"
        fullWidth={fullWidth}
        inputProps={{
          maxLength,
          ref:inputRef
        }}
        className={buildClassNames()}
        value={valueInternal != null ? valueInternal : ''}
        onChange={handleChange}
        style={inputBaseStyle.style}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={error != null}
      />
    )
  }

  let tooltipTitle = '';
  let ellipsis;
  if (!focused && inputBaseStyle.overflows) {

    if (tooltipOnOverflowEnabled) {
      tooltipTitle = valueInternal
    }

    if (ellipsisOnOverflowEnabled) {
      ellipsis = <span style={{marginBottom: '-1px'}} className={likeH2 ? classes.likeH2Ellipsis : false}>...</span>
    }
  }

  tooltipPlacement = tooltipPlacement || 'right'

  if (lazyInteractive && !interactive) {
    return <div onMouseOver={handleMouseOverLazyInteractive} onFocus={handleMouseOverLazyInteractive} css={styles.lazyInteractive}>
      {value}
    </div>
  }

  return (
    <>
      <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow={false}>
        <Box sx={{whiteSpace: 'nowrap', width: '100%'}}>{renderInputBase()}{ellipsis}</Box>
      </Tooltip>
        {/* {error != null && <Box component="span" sx={{fontSize:'10px'}}>{error}</Box>} */}
      
      {/** text width measurer */}
      {adjustWidthToText === true && (
        <Box position="relative" className={classes.hiddenBox}>
          <Box position="relative">
            <span ref={onRefChange} className={buildClassNamesMeasurer()}>
              {valueInternal}
            </span>
          </Box>
        </Box>
      )}
    </>
  );
})

const useDebounce = (value, timeout) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

const useStyles = makeStyles((theme) => ({
  inputLight: {
    color: '#afafaf',
  },
  inputBig: {
    paddingTop: '1px',
    fontSize: '20px',
    fontWeight: '400',
  },
  inputBigLight: {
    paddingTop: '1px',
    fontSize: '20px',
    fontWeight: '150',
    //color: '#646666',
  },
  inputRegular: {
    paddingTop: '1px',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: theme.typography.fontFamily,
    //color: '#646666',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    }
  },
  highlightOnHover: {
    paddingTop: '2px',
    paddingBottom: '2px',
    marginLeft: '-4px',
    '& input': {
      paddingLeft: '3px',
      borderRadius: '6px',
      border: '1px solid transparent',
    },
    '& input:hover': {
      border: '1px solid #d6e1ea'
    },
    '& input:focus': {
      border: '1px solid #d6e1ea'
      //boxShadow: '0 0 3px 1px #d6e1ea',
      //border: '1px solid transparent',
    },
    '&.Mui-error input': {
      borderColor: theme.palette.error.light,
    },
  },
  likeH2: {//Like EntityHeading
    '& input': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.8em',
      fontWeight: '400',
      color: theme.typography.h2.color,
    }
  },
  likeH2Ellipsis: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.8em',
    fontWeight: '400',
    color: theme.typography.h2.color,
  },
  likeH2Hidden: {//Like EntityHeading
    whiteSpace: 'nowrap',
    fontSize: '1.8em', 
    fontWeight: '400',
    color: theme.typography.h2.color,
    fontFamily: theme.typography.fontFamily,
  },
  ellipsis: {
    //noop for $ellipsis
  },
  bold: {//Like EntityHeading
    '& input': {
      fontWeight: '500',
    },
    '& $measurer': {
      fontWeight: '500',
    }
  },
  alignCenter: {
    '& input': {
      textAlign: 'center'
    }
  },
  hiddenBox: {
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
  measurer: {
    whiteSpace: 'nowrap'
  }
}));


InputInlineSingle.displayName = "InputInlineSingle";

export { InputInlineSingle }