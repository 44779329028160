import { notifyWindowEvent } from "modules/picasso-ui/state/windowEventUtils"
import { change, changeOld, getRatingSettings } from "modules/plinzip/api/backlogApi"
import { getAnsweringPathSettings } from "modules/plinzip/api/backlogsSettingsApi"
import { ratingSettingsFactorAdd, ratingSettingsFactorDelete } from "modules/plinzip/api/ratingApi"
import useSWR, { useSWRConfig } from "swr"
import { entityId } from "utils/apiUtils"
import { notNullNotUndefined, validateIsBoolean } from "utils/objectUtils"
import { handleResponseDataGetContent, useResolvedItemV2 } from "utils/useResolvedV2"
import { RankingEvent } from "../../backlog/rating/RankingTypes"
import { useRatingSettingsMyResolved } from "../answering/useRankingAnsweringStateMaster"
import { dataFieldInputvaluemultiplier } from "modules/picasso-ui/form/settingsForm/SettingsFormLayout"
import { notify } from "modules/yoio/errorsService"


export const getAnsweringPathSettingsFetchKey = (backlogId) => {
  notNullNotUndefined(backlogId)
  return `backlogs/${backlogId}/settings/answeringPath`
}

/**
 * Answeringpath settings are a different view on the current settings. Must be mutated when settings are changed.
 */
export const useRankingAnsweringPathSwr = (backlogId) => {
  notNullNotUndefined(backlogId)
  const swr = useSWR(getAnsweringPathSettingsFetchKey(backlogId), ()=>getAnsweringPathSettings(backlogId).then(handleResponseDataGetContent))
  return swr
}

export const useRatingSettingsEdit = (backlogId) => {

  const resolved = useResolvedItemV2(()=>backlogId ? `backlogs/${backlogId}/settings/rating` : null, ()=>getRatingSettings(backlogId), {
    revalidateOnFocus: false,
  })

  const { mutate: globalMutate } = useSWRConfig()

/*   console.log(resolved.item?.factors ? 'useRatingSettingsEdit item '+JSON.stringify(resolved.item?.factors, null, 2):'undefined') */

  const { mutate: mutateResolved } = resolved

  const mutate = () => {
    return mutateResolved().then(()=>globalMutate(getAnsweringPathSettingsFetchKey(backlogId)))
  }

  const changeRatingSettingsQuestionOld = (value) => {
    notNullNotUndefined(value);
    return changeOld({
        backlogId,
        data: {
            ratingSettings: {
                question: value,
            }
        }
      })
      .then((res)=>{
        const updated = res.data.ratingSettings
      
        return mutateResolved(()=>{
          return updated
        })
      })
  /*     .then(()=>{
          ratingSettingsMyResolved.refresh()
          return backlogResolved.refresh()
      }) */
  }

  const addFactor = (name) => {
    notNullNotUndefined(name)
    notNullNotUndefined(backlogId)

    const ratingSettings = resolved.item

    const optimisticData = {
      ...ratingSettings,
      factors: [...ratingSettings.factors, {factorId: 'tempId:'+entityId(), name, _optimisic: true, _unsaved: true}]
    }

    return mutateResolved(async ()=>{
        const res = await ratingSettingsFactorAdd({
          backlogId,
          data: {
              name
          }
        })
        return res.data
      }, 
      { optimisticData, rollbackOnError: true, revalidate: false }
    )

  }

  const changeFactor = (factor) => {
      notNullNotUndefined(factor)
      notNullNotUndefined(factor.factorId)
      notNullNotUndefined(backlogId)
    
      const ratingSettings = resolved.item

      if (factor.factorId == '1') {
        return changeRatingSettingsQuestionOld(factor.name)
      }
  
      const factorPayload = {
          name: factor.name,
      } as any

      if (factor.deleted === true) {
          factorPayload.deleted = factor.deleted
      }

      const optimisticData = {
        ...ratingSettings,
        factors: [...ratingSettings.factors.map((f)=>{
          if (f.factorId === factor.factorId) {
            f.name = factor.name
            f._optimisic = true
          }
          return f
        })]
      }

      return mutateResolved(async ()=>{

          if (factorPayload.deleted === true) {
            const res = await changeOld({
              backlogId,
              data: {
                  [`ratingSettings.factors[factorId=${factor.factorId}]`]: factorPayload
              },
            })

            return res.data.ratingSettings
          } else {

            const res = await change(backlogId, [
              {
                  op: 'replace',
                  path: `ratingSettings/factors/[factorId=${factor.factorId}]/name`,
                  value: factorPayload.name
              }
            ])
            return res.data.ratingSettings
          }

        },
        { optimisticData, rollbackOnError: true, revalidate: false }
      )

    }
    
    const deleteFactor = (factorId) => {
      notNullNotUndefined(backlogId)
      notNullNotUndefined(factorId)
      
      const ratingSettings = resolved.item

      const optimisticData = {
        ...ratingSettings,
        factors: [...ratingSettings.factors.filter((f)=>f.factorId !== factorId)]
      }

      return mutateResolved(async ()=>{
          const res = await ratingSettingsFactorDelete({
            backlogId,
            factorId,
          })
          const updated = res.data
          return updated
        },
        { optimisticData, rollbackOnError: true, revalidate: false }
      )

    }

    const factorsNotDeletedCount = resolved.item?.factors?.filter(f=>f.deleted !== true).length || null

    return {ratingSettingsResolved: resolved, ratingSettings: resolved.item, factorsNotDeletedCount, addFactor, deleteFactor, changeFactor, mutate,}

}

/**
 * legace / glue code to quickly share the logic
 */
export const useBacklogSettingsRatingSettingsEdit = (backlogId) => {

  const ratingSettingsMyResolved = useRatingSettingsMyResolved(backlogId)

  const { ratingSettingsResolved, mutate } = useRatingSettingsEdit(backlogId)

  const { item: ratingSettings, loading } = ratingSettingsResolved || {} //useResolvedItem(()=>getRatingSettings(backlogId))

  const saveRatingSettings = (data) => {
    notNullNotUndefined(data);
    return changeOld({
        backlogId,
        data: {
            ratingSettings: data
        }
    }).then(()=>{
        ratingSettingsResolved.item = {
            ...ratingSettingsResolved.item,
            ...data
        }
        return mutate().then((res)=>{
            if (ratingSettingsMyResolved.item) {//'my' ranking is probably open in the background. therefore refresh
                return ratingSettingsMyResolved.refresh()
            }
            return res;
        })
    });
  
  }

  const handleFieldChangeEvent = (e) => {
      
     const nameAndValue = getNameAndValueFromFieldChangeEvent(e)
     if (!nameAndValue) {
      return;
     }
     const { name, value } = nameAndValue

      return saveRatingSettings({
          [name]: value
      }).then(()=>{
          if (name === 'pairAnswerCap') {
              notifyWindowEvent(RankingEvent.rankingSettingsPairAnswerCapChanged, {backlogId})
          }
      })
  }

  const handleRateInputChange = (value) => {
      notNullNotUndefined(value)
      saveRatingSettings({
          ['designSettings']: {
              'rateInput': value,
          },
      })
  }

  const handleGuestsAuthOptionsChange = (value) => {
    notNullNotUndefined(value)
    saveRatingSettings({
        guestsAuthOptions: value,
    })
}

  return { ratingSettings, handleFieldChangeEvent, handleRateInputChange, handleGuestsAuthOptionsChange }

}


/**
 * Converts field input into name and value, and 'formally' validates the value.
 * 
 * Throws excepion or returns null when something fails.
 * 
 * 
 * @param e 
 * @returns 
 *  When returning null, you don't need to handle it, it notified to monitoring.
 *  It indicates an implementation error.
 */
export const getNameAndValueFromFieldChangeEvent = (e) => {
        
  let value;
  if (e.target.type === 'checkbox') {
      value = e.target.checked
      validateIsBoolean(value)
  } 
  else if (e.target.type === 'number') {
      if (e.target.value === '') {
          value = null
      } else {
          if (e.target.getAttribute(dataFieldInputvaluemultiplier)) {
              const multiplier = parseInt(e.target.getAttribute(dataFieldInputvaluemultiplier))
              value = parseInt(e.target.value) / multiplier
          } else {
              value = parseInt(e.target.value)
          }
      }
  }
  else {
      notify(new Error('unknown field type:'+e.target.type))
      return null;
  }


  const name = e.target.name
  notNullNotUndefined(name);

  return {name, value}
}