import { createContext, useContext, useEffect, useRef, useState } from "react";
import * as api from 'modules/plinzip/api/backlogApi';
import { useResolvedItem } from "utils/useResolved";
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";
import { useSignedIn } from "modules/react-auth";
import { useGuestIdentityOld } from "modules/react-auth/useGuestIdentityOld";
import { hasGroupInEac, hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder";
import { useResolvedItemV2 } from "utils/useResolvedV2";
import { notify } from "modules/yoio/errorsService";
import { useRatingSettingsEdit } from "../rankings/settings/useRatingSettingsEdit";
import { useRoomRole } from "../rankings/useRoomRole";
import { useBacklogEacSwr, useBacklogIdContext } from "../rankings/BacklogContext";
import { useRatingSettingsMyResolved } from "../rankings/answering/useRankingAnsweringStateMaster";
import { useSWRConfig } from "swr";

const RoomContext = createContext({})

export const useRoom = () => {
    return useContext(RoomContext)
}

export const RoomContextProvider = ({ isMkeyAdminPerspective, children, pwcCalculatorVersion }) => {
    
    const { me } = useSignedIn()

    const { backlogId } = useBacklogIdContext()

    const { data: backlogEac } = useBacklogEacSwr(backlogId)

    const userReady = isHavingValue(backlogEac)

    const { roomInfoResolved, notFound } = useRoomBasicData(backlogId)

    const { roomRole, action } = useRoomRole(backlogId)

    const guestIdentityApi = useGuestIdentityOld()

    const backlogResolved = useResolvedItem(()=>api.get(backlogId, {
        supressErrorMessageIfAccessError: true,
    }),null, userReady === true && backlogEac && hasGroupInEac(backlogEac, 'backlogOwner'))

    const [items, setItems] = useState()

    const { mutate } = useSWRConfig()

    const backlogPeopleResolved = useResolvedItem(()=>api.getBacklogPeople(backlogId),
        null,
        userReady && roomRole==='moderator', 
        {
            onRefreshCalled: ()=>{
                mutate(api.getBacklogsPeopleFetchUrl(backlogId))
            }
        })
    
    const { ratingSettingsResolved } = useRatingSettingsEdit(userReady && roomRole==='moderator' ? backlogId : null)

    const ratingSettingsMyResolved = useRatingSettingsMyResolved(backlogId)

    const shouldLoadRaters = userReady === true && hasPermissionInEac(backlogEac, 'backlogRatingParticipantsView')
    const ratersResolved = useResolvedItemV2(shouldLoadRaters ? `getBacklogRaters/${backlogId}` : null, ()=>{
        return api.getBacklogRaters(backlogId).then(raters=>{
            raters.forEach(u=>{
                try {
                    if (u.anonymous) {
                        if (u.seed) {
                            u.name = guestIdentityApi.generateGuestName(parseInt(u.seed))
                        }
                    }
                } catch (error) {
                    notify(error)
                }
            })
            return raters
        })
    }, { refreshInterval: 20000 })
    
    //useResolvedItem(()=>api.getBacklogRaters(backlogId), null, userReady && hasPermissionInEac(backlogEac, 'backlogRatingParticipantsView'));

    const [resultFilter, setResultFilter] = useState({})
    const shouldLoadResult = userReady && hasPermissionInEac(backlogEac, 'backlogScoringResultView')
    const scoringResultResolved = useResolvedItemV2(shouldLoadResult ? `getBacklogScoringResult/${backlogId}?pwcCalculatorVersion=`+pwcCalculatorVersion : null, ()=>api.getBacklogScoringResult(backlogId, resultFilter, null, pwcCalculatorVersion), 
        { 
            dedupingInterval: 5000,
            refreshInterval: roomRole==='moderator' && items?.length < 15 ? 15000 : 40000, 
            revalidateOnFocus: roomRole==='moderator' && items?.length < 15 ? true : false, 
        })
    
    const [loadingFilteredResult, setLoadingFilteredResult] = useState(false)

    useEffect(()=>{
        if (resultFilter?._touched !== true) {
            return;
        }
        setLoadingFilteredResult(true)
        scoringResultResolved.refresh().then(()=>{
            setLoadingFilteredResult(false)
        })
    }, [resultFilter])

    /**
     * 
     * 
     * @param {*} userId 
     * @returns 
     *      Can return null. Null is theoretically OK because user could be a voter user / unkown user.
     *      In most cases there should be a name because its a rater with a guest ID
     *      
     */
     const getUserNameByUserId = (userId) => {
        notNullNotUndefined(userId)

        if (!backlogPeopleResolved.item) {
            return null
        }

        const user = backlogPeopleResolved.item.find(u=>u.userId === userId);
        if (user) {
            return user.name
        }

        return null
    }

    const refreshResult = (options) => {
        if (!backlogEac) {
            // Do nothing
            return Promise.resolve()
        }
        if (!hasPermissionInEac(backlogEac, 'backlogScoringResultView')) {
            // Do nothing
            return Promise.resolve()
        }
        const { resetFilter } = options || {}
        if (resetFilter === true) {
            setResultFilter({_touched: true}) //will automatically trigger refresh
            return Promise.resolve()
        } else {
            return scoringResultResolved.refresh().then(()=>{
                if (hasPermissionInEac(backlogEac, 'backlogRatingParticipantsView')) {
                    return ratersResolved.refresh()
                }
            })
        }
    }

    const prevNewestFactorId = useRef(null)

    const onFactorAddedOrRemovedRefreshResult = () => {
        if (!ratingSettingsResolved.item) {
            return
        }
        if (!scoringResultResolved.item?.hasResponses) {
            //no need to refresh results
            return;
        }

        if (!ratingSettingsResolved.item.factors) {
            return;
        }

        const newestFactorId = [...ratingSettingsResolved.item.factors.map(f=>f.factorId)].sort().reverse()[0]

        if (prevNewestFactorId.current !== null && newestFactorId !== prevNewestFactorId.current) {
            //some factor changed
            refreshResult()
        }

        prevNewestFactorId.current = newestFactorId
    }

    useEffect(()=>{
        onFactorAddedOrRemovedRefreshResult()
    }, [ratingSettingsResolved.item])

    //useResolvedItem(()=>api.getBacklogScoringResult(backlogId), null, userReady && hasPermissionInEac(backlogEac, 'backlogScoringResultView'))

    const ready = roomRole && 
    (
        (roomRole === 'moderator' && isHavingValue(backlogResolved.item) && isHavingValue(ratingSettingsResolved.item) )
        ||
        (roomRole === 'guest' && isHavingValue(ratingSettingsMyResolved.item) )
    )

    useEffect(()=>{
        if (!userReady || roomRole!=='moderator') {
            return;
        }
        if (!backlogResolved.item) {
            return;
        } 

        setItems(backlogResolved.item.items)

        backlogPeopleResolved.refresh()
    },[backlogResolved.item])

    /**
     * just saves the change. call refresh yourself on the objects you want to refresh
     * 
     * @param {*} field 
     * @param {*} value 
     * @returns 
     */
    const changeRatingSettingsField = (field, value) => {
        notNullNotUndefined(field);
        const data = {
            [field]: value
        }
    
        return api.changeOld({
            backlogId,
            data: {
                ratingSettings: data
            }
        }).then((res)=>{

            ratingSettingsResolved.mutate(()=>({
                ...ratingSettingsResolved.item,
                ...data
            }))

            if (field === 'guestsHaveItemCreatePermission' && ratingSettingsMyResolved.item) {
                return ratingSettingsMyResolved.refresh()
            } 
            else if (field === 'collectTogetherPhase' && ratingSettingsMyResolved.item) {
                return ratingSettingsMyResolved.refresh()
            }
            else if (field === 'delimitStep' && ratingSettingsMyResolved.item) {
                return ratingSettingsMyResolved.refresh()
            }

            return res;
        })
    }

    const onItemNameChanged = (item, name) => { //is called by some component after its already saved. todo: move saving here
        notNullNotUndefined(item)
        notNullNotUndefined(name)

        if (scoringResultResolved?.item?.total?.items) {
            let found = scoringResultResolved.item.total.items.find(i=>i.itemId === item.itemId)
            if (found) {
                found.name = name
            }
        }
        if (scoringResultResolved?.item?.my?.items) {
            let found = scoringResultResolved.item.my.items.find(i=>i.itemId === item.itemId)
            if (found) {
                found.name = name
            }
        }
        if (items) {
            let found = items.find(i=>i.itemId === item.itemId)
            if (found) {
                found.name = name
            }
        }

    }

    const addOptimisticItem = (item) => {
        notNullNotUndefined(item)
        notNullNotUndefined(item.itemId)

        setItems((current)=>[
            ...current, item
        ])
    }

        //new
        const setGuestIdentityIfNecessary = () => {
            if (!roomInfoResolved.item) {
                throw new Error("roomInfoResolved must be resolved before calling this method")
            }
    
            //Access situations:
            //A: Admin in workspace (workspace of backlog) (requires no guest identity)
            //B: Admin with mkey (requires guest identity)
            //C: Guest (requires guest identity)
    
            if ((!isMkeyAdminPerspective || (isMkeyAdminPerspective && guestIdentityApi.hasGuestIdentity()))
                && hasPermissionInEac(roomInfoResolved.item.eacEffective, 'backlogRate')
            ) {
                //everything fine
                //A, B, and C go here if all required conditions met
    
                //NOOP
                return Promise.resolve();
            } else {
    
                //B and C go here
    
                let giveGuestIdentity = false;
    
                //if is admin via mkey
                if (isMkeyAdminPerspective && hasGroupInEac(roomInfoResolved.item.eacEffective, 'backlogOwner')
                    && roomInfoResolved.item.eacEffective.isGuestIdentity
                ) {
                    giveGuestIdentity = true
                } 
                 //if is guest. this is not the real permission check. just an 'additional check'
                else if (roomInfoResolved.item?.submitPublic === true) {
                    giveGuestIdentity = true
                }
    
                if (giveGuestIdentity) {
                    if (!guestIdentityApi.hasGuestIdentity()) { // check not necessary but just to make sure to not overwrite
                        return guestIdentityApi.createGuestIdentity(true)
                            .then(()=>{
                                return me.reloadMe()
                            })
                            .then(()=> {
                                //refresh with new me with guest identity
                                return roomInfoResolved.refresh()
                            })
                    } 
                }
    
            }
    
            return Promise.resolve()
        }

    const getContainsExampleOptionsOnlyOrEmpty = (items) =>{
        if (items.length > 10) {//shortcut - assuming those are no example options anymore
            return false
        }

        if (items.length === 0) {
            return true
        }

        return items.every(i=>i.name && i.name.toLowerCase().startsWith('example option'))
    }

    if (!userReady) {
        return <RoomContext.Provider value={{ notFound }}>{children}</RoomContext.Provider>
    }

    if (!ready) {
        return <RoomContext.Provider value={{ notFound }}>{children}</RoomContext.Provider>
    }

    const containsExampleOptionsOnlyOrEmpty = isHavingValue(items) ? getContainsExampleOptionsOnlyOrEmpty(items) : null

    return (
        <RoomContext.Provider value={{ 
            backlogId, 
            backlogEac, 
            roomInfoResolved,
            roomRole,
            backlogResolved, //will only have item inside if moderator
            ratingSettingsResolved,  //will only have item inside if moderator
            scoringResultResolved, 
            ratersResolved,
            refreshResult, 
            changeRatingSettingsField,
            onItemNameChanged,
            ratingSettingsMyResolved,
            ready,
            notFound,
            getUserNameByUserId,
            items,
            backlogPeopleResolved,
            setGuestIdentityIfNecessary,
            setResultFilter,
            resultFilter,
            loadingFilteredResult,
            containsExampleOptionsOnlyOrEmpty,
            addOptimisticItem,
            action: action || 'standard'
            }}>
                {children}
        </RoomContext.Provider>
    )

}

//quick and dirty way to migrate the loading of the very basic room data into this component as well. 
export const useRoomBasicData = (backlogId) => {

    const roomInfoResolved = useResolvedItem(()=>api.getRoomInfo(backlogId), null, backlogId !== null)

    const [notFound, setNotFound] = useState(false)

    return { roomInfoResolved, notFound }

}