import { Typography, Tooltip, TypographyProps } from "@mui/material";
import { useRef, useEffect, FC, PropsWithChildren } from 'react';
import { useState } from "react";

const styleDef = {
   root: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    minWidth: '0'
   },
   fullWidth: {
    width: '100%',
   }
}

export interface TypographyEllipsisProps {
    className?: string
    tooltipOnOverflowEnabled?: boolean 
    fullWidth?: boolean
    style?: any
    sx?: any
}

export const TypographyEllipsis: FC<PropsWithChildren<TypographyEllipsisProps & TypographyProps>> = ({className, children, tooltipOnOverflowEnabled, fullWidth, ...other}) => {

    const style = [styleDef.root, fullWidth !== false ? styleDef.fullWidth : null, other.style]
    if (other.sx) {
        if (Array.isArray(other.sx)) {
            style.push(...other.sx)
        } else {
            style.push(other.sx)
        }
    }

    const ref = useRef<HTMLElement>()

    const [overflowing, setOverflowing] = useState(false)

    useEffect(()=>{
        if (!tooltipOnOverflowEnabled) {
            return;
        }

        if (ref.current && (ref.current?.scrollWidth > ref.current?.offsetWidth)) {
            setOverflowing(true)
        }
    },[ref.current])

    const renderInner = () => <Typography ref={ref} {...other} className={className} sx={style}>{children}</Typography>

    const tooltipTitle = tooltipOnOverflowEnabled && children && overflowing ? children : ''

    return <>
        {tooltipOnOverflowEnabled ? 
            <Tooltip title={tooltipTitle} placement="right">
                {renderInner()}
            </Tooltip>
            :
            renderInner()
        }
    </>

}