import { useQueryParam } from "modules/picasso-modules/nextjs/commonUtils"
import { getAnsweringMyState, getBacklogRatingMySettings } from "modules/plinzip/api/ratingApi"
import { useSignedIn } from "modules/react-auth"
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import { useGuestIdentityOld } from "modules/react-auth/useGuestIdentityOld"
import { useErrorNotify } from "modules/yoio/errorsService"
import alerts from "modules/yoio/state/alerts"
import { createContext, ReactNode, useContext, useEffect, useId } from "react"
import { useRef } from "react"
import useSWR, { KeyedMutator, MutatorCallback } from "swr"
import { nowEpochSeconds } from "utils/dateUtils"
import { notNullNotUndefined } from "utils/objectUtils"
import { handleResponseDataGetContent, useResolvedItemV2 } from "utils/useResolvedV2"
import { BacklogType, GuestAuthOption } from "../../backlog/rating/RankingTypes"
import { useBacklogEacSwr, useBacklogType } from "../BacklogContext"
import { useRoomRole } from "../useRoomRole"
import { RankingAnsweringMode, RankingAnsweringState, RankingAnsweringStep } from "./RankingAnsweringTypes"

export interface RankingAnsweringStateMasterReturn {
    answeringState: RankingAnsweringState
    hasPermissionToBecomeAnswering: boolean
    guestsAuthPossibleForMe: boolean
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onCoverStepFinished: () => void
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onNameStepFinished: () => void
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onSegmentStepFinished: () => void
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onRatingHelpStepFinished: () => Promise<any>
    /**
     * Go to the answering begin. The anwering will determine the first step for this Ranking.
     */
    gotoAnsweringBegin: () => void
    /**
     * Go to a specific step
     */
    gotoStep: (step: RankingAnsweringStep) => void
    /**
     * When the user wants / should continue answering (go to the actual rating step)
     */
    continueAnswering,
    /**
     * Start answering the ranking as a new user.
     * 
     * This will clear the current answering user.
     * If current user is a guest, this will clear the guest id.
     * 
     * Only supported if:
     *  - the guestsAuthOption is GuestAuthOption.unauth
     *  - answeringMode is RankingAnsweringMode.terminal
     * (Could be feasible for other cases, but not implemented / tested as there is no case.)
     * 
     */
    startAsNewUser: () => void
    gotoChangeAnsweringUserView: () => void
    mutate: KeyedMutator<RankingAnsweringState>
}

export interface RankingAnsweringStateSettingsReturn {
    step?: RankingAnsweringStep
    ignoreRatingsMy?: boolean
    ignoreTerminalKey?: boolean
}

export const RankingAnsweringStateSettingsContext = createContext<RankingAnsweringStateSettingsReturn>({} as RankingAnsweringStateSettingsReturn)

export const useRankingAnsweringStateSettingsContext = () => {
    return useContext<RankingAnsweringStateSettingsReturn>(RankingAnsweringStateSettingsContext)
}

export const getAnsweringStateMyFetchKey = (backlogId: string, ignoreRatingsMy?: boolean) => {
    notNullNotUndefined(backlogId)
    return `rankings/${backlogId}/answering/my/state?ignoreRatingsMy=${ignoreRatingsMy}`
} 

export const getRatingSettingMyFetchKey = (backlogId) => {
    notNullNotUndefined(backlogId)
    return `${backlogId}/ratingSettings/my`
}

export interface RankingAnsweringSession {
    step?: RankingAnsweringStep
    nameStepFinished: boolean
    segmentStepFinished: boolean
    ratingHelpStepFinished: boolean
}


const useRankingAnsweringSession = (backlogId) => {

    const getInitialSession = ()=>{
        return {
            step: null,
            nameStepFinished: false,
            segmentStepFinished: false,
            ratingHelpStepFinished: false,
        }
    }

    const { data: answeringSession, mutate } = useSWR<RankingAnsweringSession>(backlogId ? `/ranking/${backlogId}/myuistate`:null,()=>getInitialSession(), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    
    const setAnsweringSession = (data?: RankingAnsweringSession | Promise<RankingAnsweringSession> | MutatorCallback<RankingAnsweringSession>) => {
        return mutate(data, { revalidate: false })
    }

    const setStep = (step: RankingAnsweringStep) => {
        return mutate((cur)=>({...cur, step}), { revalidate: false })
    }

    const clearStep = () => {
        return mutate((cur)=>({...cur, step: null}), { revalidate: false })
    }

    const clearAnsweringSession = () => {
        return mutate((cur)=>(getInitialSession()), { revalidate: false })
    }

    return { answeringSession, setAnsweringSession, setStep, clearStep, clearAnsweringSession }
}


const RankingAnsweringStateContext = createContext<RankingAnsweringStateMasterReturn>({} as RankingAnsweringStateMasterReturn)

export const useRankingAnsweringStateContext = () => {
    return useContext<RankingAnsweringStateMasterReturn>(RankingAnsweringStateContext)
}

export const RankingAnsweringStateContextProvider = ({backlogId,children}:{backlogId: string, children?: ReactNode}) => {

    const value = useRankingAnsweringStateMaster(backlogId)

    return <RankingAnsweringStateContext.Provider value={value}>{children}</RankingAnsweringStateContext.Provider>

}

export const useRankingAnsweringStateMaster = (backlogId: string):RankingAnsweringStateMasterReturn => {

    const { notify } = useErrorNotify()

    const answeringStateSettings = useRankingAnsweringStateSettingsContext()
    const ignoreRatingsMy = answeringStateSettings?.ignoreRatingsMy

    const testId = useId()

    const stateDeps = useRef<{data: boolean}>({
        data: false
    })

    const { createGuestIdentity, hasGuestIdentitySigned, clearGuestIdentity } = useGuestIdentityOld()

    const { data: eac, mutate: mutateEac } = useBacklogEacSwr(backlogId)

    const hasPermissionToBecomeAnswering = eac && hasPermissionInEac(eac, 'backlogRateView')

    const ratingSettingsMyResolved = useRatingSettingsMyResolved(backlogId)

    const { me } = useSignedIn()

    const type = useBacklogType(backlogId)

    const { roomRole } = useRoomRole(backlogId)

    // Used only for debug
    const { value: stepFromQueryParam } = useQueryParam('step')

    const { answeringSession, setAnsweringSession, setStep, clearAnsweringSession } = useRankingAnsweringSession(backlogId)

    

    const swr = useSWR<RankingAnsweringState>(backlogId && hasPermissionToBecomeAnswering ? getAnsweringStateMyFetchKey(backlogId, ignoreRatingsMy) : null,
        ()=>{ 
            const sessionStepsFinished = []
            if (answeringSession?.nameStepFinished) {
                sessionStepsFinished.push(RankingAnsweringStep.name)
            }
            if (answeringSession?.segmentStepFinished) {
                sessionStepsFinished.push(RankingAnsweringStep.segment)
            }
            if (answeringSession?.ratingHelpStepFinished) {
                sessionStepsFinished.push(RankingAnsweringStep.ratingHelp)
            }
            return getAnsweringMyState(backlogId, ignoreRatingsMy, sessionStepsFinished).then(handleResponseDataGetContent)
        }, 
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
        }
    )

    const { guestsAuthPossibleForMe } = useGuestAuthPossibleForMe(backlogId)
    const shouldRequireGuestAuth = guestsAuthPossibleForMe

    const guestsAuthCredentialStatusSwr = useSWR(`backlogs/${backlogId}/myCredentialStatus`,()=>{
        if (shouldRequireGuestAuth) {
            if (eac.exp) {
                if (nowEpochSeconds() > eac.exp) {
                    return Promise.resolve({ expired: true })
                }
            }
        }
        return Promise.resolve({expired: false})
    }, {refreshInterval: shouldRequireGuestAuth ? 10000:null})


    const isNameRequired = () => {
        if (swr.data.guestIdentityRequired === false) {
            return false
        }

        if (swr.data.guestIdentityRequired === true && roomRole === 'moderator') {
            //moderator is not prompted for name
            return false
        }

        if (ratingSettingsMyResolved.item.guestsMustEnterName !== true) {
            return false
        }

        return true;
    }

    /** 
     * Step transitions
    */

    const gotoStep = (targetStep: RankingAnsweringStep) => {
        notNullNotUndefined(targetStep)

        console.log('gotoStep from ' + answeringSession?.step + ' to ' + targetStep)

        if (answeringSession?.step !== targetStep) {
            setStep(targetStep)
            return Promise.resolve()
        }

        return Promise.resolve()
    }

    const gotoAnsweringBegin = (startStep?: RankingAnsweringStep) => {

        if (answeringStateSettings?.step) {

            // Exception: not enough items can be shown even if it is a fixed step.
            // notEnoughItems should not be a step at all but an error state
            if (swr.data.step === RankingAnsweringStep.notEnoughItems) {
                return gotoStep(RankingAnsweringStep.notEnoughItems)
            } else {
                return gotoStep(answeringStateSettings.step)
            }

        }

        if (type === BacklogType.ROOM && shouldRequireGuestAuth) {
            if (guestsAuthCredentialStatusSwr.data.expired) {
                gotoStep(RankingAnsweringStep.login)
                notify(new Error('ranking credential expired. showing login'), (event)=>{
                    event.severity = 'info'
                })
                return Promise.resolve();
            }
        }
        if (type === BacklogType.ROOM  && swr.data.guestIdentityRequired && !hasGuestIdentitySigned()) {
            return createGuestIdentity()
                .then(()=>{
                    return me.reloadMe()
                })
                .then(()=>{
                    return mutateEac()
                })
        }

        if (startStep) {
            return gotoStep(startStep)
        } else {
            if (type === BacklogType.ROOM) {
                return gotoStep(RankingAnsweringStep.rating)
            }
        }

        throw new Error('unexpected state')
    }


    const startAsNewUser = () => {
        if (swr.data.mode !== RankingAnsweringMode.terminal) {
            alerts.error('not possible')
        }
        return clearGuestIdentity()
            .then(()=>clearAnsweringSession())
            .then(()=>{
            return mutateEac()
/*             .then(()=>{
                return Promise.all([ratingSettingsMyResolved.refresh()]).then(()=>{
                    return swr.mutate()
                })
            }) */
        })
    }


    /**
     * Events
     */

    useEffect(()=>{
        if (!eac) {
            return;
        }
        if (!type) {
            return;
        }
        if (!ratingSettingsMyResolved.item) {
            return;
        }
        if (!swr.data) {
            return;
        }
        if (!guestsAuthCredentialStatusSwr.data) {
            return;
        }

        // Temporary. Remove later.
        //console.log('exp', guestsAuthCredentialStatusSwr.data?.expired)
        
        // --- Used only for debug (stepFromQueryParam)
        if (stepFromQueryParam) {
            gotoStep(stepFromQueryParam)
            return;
        }

        gotoAnsweringBegin(swr.data?.step)

    },[swr.data, ratingSettingsMyResolved.item, stepFromQueryParam, stateDeps.current.data ? guestsAuthCredentialStatusSwr.data:null, type, eac])

    const stepFinishedMutateState = (step: RankingAnsweringStep) => {
        notNullNotUndefined(step)
        return swr.mutate().then(updated=>{

            if (updated.finished[step] !== true) {
                // Could happen if DB does not have changes yet. If that ever happens, use a retry
                notify(new Error('step finished (' + step + '), mismatch between api and ui.'))
                alerts.error('could not save answer. please try again.')
            }

        })
    }

    const onNameStepFinished = () => {
        setAnsweringSession((cur)=>({...cur, nameStepFinished: true}))
            .then(()=>swr.mutate())
        
/*         if (ratingSettingsMyResolved.item.collectTogetherPhase === true) {
            gotoStep(RankingAnsweringStep.collectTogether)
        } else {
            console.log('a')
            gotoStep(RankingAnsweringStep.rating)
        } */
    }

    const onSegmentStepFinished = () => {
        setAnsweringSession((cur)=>({...cur, segmentStepFinished: true}))
            .then(()=>stepFinishedMutateState(RankingAnsweringStep.segment))
    }

    const onRatingHelpStepFinished = () => {
        return setAnsweringSession((cur)=>({...cur, ratingHelpStepFinished: true}))
            .then(()=>stepFinishedMutateState(RankingAnsweringStep.ratingHelp))
    }

    const gotoChangeAnsweringUserView = () => {
        if (!shouldRequireGuestAuth) {
            console.error('shouldRequireGuestAuth not true')
            return;
        }
        gotoStep(RankingAnsweringStep.login)
    }

    const continueAnswering = () => {

        return swr.mutate().then((updated)=>{

            console.log('updated.step', updated.step)
            return gotoAnsweringBegin(updated.step || null)
        })

/*         if (isNameRequired()) {
             gotoStep(RankingAnsweringStep.name)
        } else {
             onNameStepFinished()
        } */
    }

    const onCoverStepFinished = () => {
        // migrate later: cover step not public currently
        //return continueAnswering()
    }

/*     let answeringState = null;
    if (swr.data) {
        const identityReady = swr.data.guestIdentityRequired !== true || hasGuestIdentitySigned()
        answeringState = identityReady ? swr.data : null
    } */

    return {
        get answeringState() {
            //stateDeps.current.data = true
            if (!swr.data) {
                return swr.data
            }
            const identityReady = swr.data.guestIdentityRequired !== true || hasGuestIdentitySigned()
            if (!identityReady) {
                console.log('identityReady', identityReady)
            }

            let step = answeringSession?.step || swr.data?.step || null
            if (step === RankingAnsweringStep.segment && !eac.userId) {
                // wait until guestIdentity is given.
                step = null
            }

            return identityReady ? {...swr.data, step } : null
        },
        mutate: swr.mutate,
        onCoverStepFinished,
        onNameStepFinished,
        onSegmentStepFinished,
        onRatingHelpStepFinished,
        gotoChangeAnsweringUserView,
        gotoAnsweringBegin,
        continueAnswering,
        guestsAuthPossibleForMe,
        gotoStep,
        startAsNewUser,
        hasPermissionToBecomeAnswering,
    }

}


export const useRatingSettingsMyResolved = (backlogId) => {
    
    const { data: eac } = useBacklogEacSwr(backlogId)

    const ratingSettingsMyResolved = useResolvedItemV2(backlogId && eac && hasPermissionInEac(eac, 'backlogRateView') ? getRatingSettingMyFetchKey(backlogId) : null, ()=>getBacklogRatingMySettings(backlogId), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    return ratingSettingsMyResolved
}

export const useGuestAuthPossibleForMe = (backlogId) => {
    notNullNotUndefined(backlogId)

    const { data: eac } = useBacklogEacSwr(backlogId)

    const ratingSettingsMyResolved = useRatingSettingsMyResolved(backlogId)

    const guestsAuthPossible = ratingSettingsMyResolved.item?.guestsAuthOptions?.some(o=>o!==GuestAuthOption.unauth)
    const guestsAuthPossibleForMe = eac && !eac.isWorkspaceMember && guestsAuthPossible

    return { guestsAuthPossibleForMe }
}