import { css } from '@emotion/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import { forwardRef } from "react"

const style = (theme)=>css({
    display: 'flex',
    alignItems: 'center',
    '&>*:not(:last-child)': {
        marginRight: theme.spacing(1)
    }
})


export interface VerticalAlignProps {
}

export const VerticalAlign: FunctionComponent<PropsWithChildren<VerticalAlignProps>> = forwardRef<HTMLDivElement, PropsWithChildren<VerticalAlignProps>>(({children, ...props},ref) => {
    return <div css={style} ref={ref} {...props}>{children}</div>
})