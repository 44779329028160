import { Avatar, Box, Button, IconButton, Typography, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { InputInlineSingle } from "components/form/InputInlineSingle"
import { VerticalAlign } from "modules/picasso-ui/layout/VerticalAlign"
import { itemChangeName, itemUnclearVoteCreateMy, itemUnclearVoteDeleteMy, itemUnclearVoteDeleteAll } from "modules/plinzip/api/backlogApi"
import { useRoom } from "../RoomContext"
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { notNullNotUndefined } from "utils/objectUtils"
import { useState } from "react"
import ProgressButton from "modules/picasso-ui/form/ProgressButton"
import { useDefaultSwr } from "modules/yoio/api/useDefaultSwr";
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder";
import { useBacklogEacSwr } from "../../rankings/BacklogContext";

const useStyles = makeStyles((theme)=>({
    itemNameEditInline: {
        fontWeight: '500',
    },
    itemUnclearIcon: {
        color: '#e5e5e5',
    },
    itemUnclearIconTrue: {
        color: theme.palette.primary.light,
    },
}))

export const RoomPeopleAsAvatarList = ({userIds, spacing}) => {

    const { backlogId, getUserNameByUserId } = useRoom()

    const { data: eac } = useBacklogEacSwr(backlogId)

    const { data: people } = useDefaultSwr(eac && hasPermissionInEac(eac, 'backlogScoringResultView') ? `/backlogs/${backlogId}/people` : undefined)

    const findUserName = (userId) => {
        let name;
        
        if (!name) {
            if (people) {
                name = people.find(u=>u.userId===userId)?.name
            }
        }

        if (!name) {
            name = getUserNameByUserId(userId)
        }

        return name
    } 

    return (
        <>
            {userIds.map((userId, idx)=>{
                const proposedByName = findUserName(userId)|| "Unknown"
                return (
                    <Box pt={idx !== 0 ? spacing : 0} pb={spacing} key={idx}>
                        <RoomPeopleAsAvatarListItem name={proposedByName}/>
                    </Box>
                )
            })}
        </>

    )

}

export const RoomPeopleAsAvatarListItem = ({name}) => {

    return (
        <VerticalAlign>
            <Avatar style={{width: '25px', height: '25px'}}/> <Typography>{name}</Typography>
        </VerticalAlign>
    )

}

export const ItemProposedByInfo = ({author}) =>{

    const { backlogPeopleResolved } = useRoom()

    return (
        <>
            {backlogPeopleResolved.item?.length > 1 &&
                <>
                    <Box mb={2} color="text.secondary">
                        <Typography style={{fontWeight: '600', fontSize: '12px'}}>Proposed by</Typography>
                    </Box>
                    {author ?
                        <RoomPeopleAsAvatarList userIds={[author]} />
                        :
                        <RoomPeopleAsAvatarListItem name="Unknown" />
                    }
                </>
            }
        </>
    )
}

export const UnclearToUsersInfo = ({itemId, unclearVotes, clarifyButtonVisible, onAfterClarifySuccess}) =>{

    const { backlogId } = useRoom()

    const [progress, setProgess] = useState()

    const handleClarifyClick = () => {
        setProgess(true)

        itemUnclearVoteDeleteAll(backlogId, itemId).then(()=>{
            setProgess(false)
            if (onAfterClarifySuccess) {
                onAfterClarifySuccess()
            }
        })
        .catch(()=>{
            setProgess(false)
        })
    }

    return (
        <>
            <Box mb={2} color="text.secondary">
                <Typography style={{fontWeight: '600', fontSize: '12px'}}>Marked as unclear by:</Typography>
            </Box>
            <RoomPeopleAsAvatarList spacing={1} userIds={unclearVotes.map(i=>i.userId)} />
            {clarifyButtonVisible === true &&
                <Box textAlign="right">
                    <Tooltip title="Click to set clarified">
                        <ProgressButton size="small" variant="outlined" onClick={handleClarifyClick} loading={progress}>Clarify</ProgressButton>
                    </Tooltip>
                </Box>
            }
        </>
    )
}

export const ItemNameEditInline = ({item}) => {

    const classes = useStyles()

    const { backlogId, onItemNameChanged } = useRoom()

    const { name } = item

    //is debounced because InputInlineSingle is debounced
    const handleNameChange = (name) => {
/*         if (name.length === 0) {
            return;
        } */

        item.name = name;
        itemChangeName({
          backlogId,
          itemId: item.itemId,
          name,
        })

        onItemNameChanged(item, name)
    }

    return (
        <InputInlineSingle
            value={name}
            onChange={handleNameChange}
            minLength={0}
            maxLength={500}
            variant="inputRegular"
            adjustWidthToText={true}
            fullWidth={false}
            highlightOnHover={true}
            tooltipOnOverflowEnabled={true}
            className={classes.itemNameEditInline}
        /> 
    )

}

export const ItemUnclearVoteButton = ({itemId, value, onUnclearVoteChangeSuccess}) => {
    notNullNotUndefined(itemId)

    const classes = useStyles()

    const { backlogId } = useRoom()

    //to show the state in the ui before the vote is saved
    const [clicked, setClicked] = useState(value) //'value' is the saved state form db

    const changeSuccess = () => {
        if (onUnclearVoteChangeSuccess) {
            onUnclearVoteChangeSuccess(itemId)
        }
    }

    const handleClick = () => {
        if (clicked === true) {
            setClicked(false)
            itemUnclearVoteDeleteMy(backlogId, itemId).then(changeSuccess)
        } else {
            setClicked(true)
            itemUnclearVoteCreateMy(backlogId, itemId).then(changeSuccess)
        }
    }

    return (
        <Tooltip title={clicked ? "You marked this as unclear" : "Mark as unclear"}>
            <IconButton 
                size="small" 
                onClick={handleClick} 
                >
                {clicked === true ? 
                    <PriorityHighIcon className={classes.itemUnclearIconTrue} />
                    :
                    <PriorityHighIcon className={classes.itemUnclearIcon} />
                }
            </IconButton>
        </Tooltip>
    )

}